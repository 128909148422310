$(document).ready(function() {
    //Hide HoneyPot
    $('#companyemail').addClass('hide');
  });
  // Wait for the DOM to be ready
$(function() {
  // Initialize form validation on the registration form.
  // It has the name attribute "registration"
  $("form[name='contact-form']").validate({
    // Specify validation rules
    rules: {
      // The key name on the left side is the name attribute
      // of an input field. Validation rules are defined
      // on the right side
      fullname: "required",
      email: {
        required: true,
        // Specify that email should be validated
        // by the built-in "email" rule
        email: true
      },
      message: "required"
    },
    // Specify validation error messages
    messages: {
      fullname: "",
      email: "",
      message: ""
    },

    highlight: function (element) {
      $(element).closest('.control').removeClass('has-success').addClass('has-error')
  },
  unhighlight: function (element) {
      $(element).closest('.control').removeClass('has-error').addClass('has-success');
  },
    // Make sure the form is submitted to the destination defined
    // in the "action" attribute of the form when valid
    submitHandler: function(form, event) {
      //form.submit();

      event.preventDefault();
  var r = !0,
      s = form,
      e = $(s).siblings(".contact-form-result"),
      o;
  // Escape if the honeypot has been filled
  if (!!$("#companyemail").val()) return;
  if (!!$("#secondcontact").val()) return;

      //var c = $(this).serialize();
      var fullname = $("#fullname").val();
      var email = $("#email").val();
      var message = $("#message").val();
      var messageLanguage = $("#language").val();
      var data = {
         messageLanguage : messageLanguage,
         fullname : fullname,
         email : email,
         message : message }
      $.ajax({
          url: "https://igw0p0fiwa.execute-api.ap-south-1.amazonaws.com/production/sendMail",
          type: "post",
          dataType: "json",
          crossDomain: "true",
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify(data),

          beforeSend: function(data) {
              $('#submit').attr('disabled', true);
              //$('#status').html('<i class="fa fa-refresh fa-spin"></i> Sending Mail...').show();
              if ($("#language").val() == "km") {
                  o = '<p class="form-message form-success">កំពុង​ផ្ញើ...</p>';
              } else {
                  o = '<p class="form-message form-success">Sending...</p>';
              }
              e.removeClass("hidden").html(o)
          },

          success: function (data) {
              // clear form and show a success message
              // check form language
              console.log(data)
              if (data == "Email sent!") {
                  if ($("#language").val() == "km") {
                      var successMessage = "អរគុណ​សម្រាប់​ការ​ផ្ញើ​សារ​របស់​លោក​អ្នក យើង​ខ្ញុំ​នឹង​ឆ្លើយ​តប​ទៅ​លោក​អ្នក​វិញ តាម​លទ្ធភាព​ដែល​យើងខ្ញុំ​អាច​ធ្វើ​ទៅ​បាន។";
                  } else {
                      var successMessage = "Thank you for your message. We will get back to you as soon as we can.";
                  }
              }
              o = '<p class="form-message form-success">' + successMessage + '</p>';
              e.removeClass("hidden").html(o);
              $(s)[0].reset();
              setTimeout(function() {
                  e.addClass("hidden").html("");
              }, 5e3);
              $('#submit').attr('disabled', false);
            },
          error: function (jqXHR, textStatus, errorThrown) {
              // show an error message
              if ($("#language").val() == "km") {
                  var errorMessage = 'សូម​មេត្តា​បំពេញ​ពេល​ក្រោយ ពីព្រោះ​មាន​កំហុស​ខ្លះៗ​ខណៈ​ពេល​ដែល​លោក​អ្នក​កំពុង​បញ្ជូន​ទម្រង់​បែប​បទ​មក​កាន់​ខាង​យើង។ កំហុស៖ ' + jqXHR.status;
              } else {
                  var errorMessage = 'Sorry, there was an error. Please try again later. ERROR: ' + jqXHR.status;
              }
              o = '<p class="form-message form-error">' + errorMessage + '</p>';
              e.removeClass("hidden").html(o);
              setTimeout(function() {
                  e.addClass("hidden").html("");
              }, 5e3);
              $('#submit').attr('disabled', false);
            }
          });
  






    }
  });
});



